<template>
  <div class="container">
    <div class="checkin">
      <el-row :gutter="20">
        <el-col :span="5">
          <serviceleft :activeIndex="leftPannelSelected"/>
        </el-col>
        <el-col :span="19">
          <div class="right_pannel">
            <div class="flightList">
              <!-- @errorEvent="errorEvent" @loadingEvent="loadingEvent" -->
              <div class="signBar">
                <div class="signBar__inner">
                  <div
                    style="padding-bottom: 10px;-webkit-box-shadow: 0 1px 5px 1px #eaeaea; 
                    box-shadow: 0 1px 5px 1px #eaeaea;padding: 0px 0px;"
                  >
                    <div style="margin-left: 20px;">
                      <el-row v-if="loginType=='AIRPORT'" style="line-height: 45px;">
                        <el-row>
                          <el-col :span="7" class="form-group">
                            <div class="filter">
                              <p class="filter-title">姓名</p>
                              <p class="filter-value">{{userInput.name}}</p>
                            </div>
                          </el-col>
                          <el-col :span="7" class="form-group">
                            <div class="filter">
                              <p class="filter-title">电话电话</p>
                              <p class="filter-value">{{userInput.phone}}</p>
                            </div>
                          </el-col>
                          <el-col :span="7" class="form-group">
                            <div class="filter">
                              <p class="filter-title">凭证号码</p>
                              <p class="filter-value">{{userInput.cert}}</p>
                            </div>
                          </el-col>
                        </el-row>
                        <el-col :span="3" class="form-group">
                          <div class="filter">
                            <p class="filter-title" v-if="nowSeat">
                              <el-tag type="danger">座位：&nbsp;{{nowSeat.row}}排{{nowSeat.col}}座</el-tag>
                            </p>
                          </div>
                        </el-col>
                      </el-row>
                      <div class="flightList" v-loading="loadingShow">
                        <!-- @errorEvent="errorEvent" @loadingEvent="loadingEvent" -->
                        <div class="top tour-content">
                          <div>
                            <div class="tour-content-flight">
                              <span>{{journey.tours.depCityName}} - {{journey.tours.arrCityName}}</span>
                            </div>
                            <div class="tour-content-time">
                              <div class="filter">
                                <p class="filter-title">航班号</p>
                                <p class="filter-value">{{journey.tours.flightNo}}</p>
                              </div>
                              <div class="filter">
                                <p class="filter-title">出发时间</p>
                                <p
                                  class="filter-value"
                                >{{journey.tours.flightDate}} {{journey.tours.takeOffTime}}</p>
                              </div>
                            </div>
                          </div>
                          <div @click="toConfirm" class="btn" style="cursor: pointer;">确认选座</div>
                        </div>
                      </div>
                      <!-- <el-row>
                        <el-col :span="24" class="form-group">
                          <div style="margin: auto;width:300px;">
                            <el-button
                              type="danger"
                              style="width:100px;border-radius: 20px;"
                              @click="toConfirm"
                              v-loading="loadingShow"
                            >确认选座</el-button>
                            <el-button
                              type="danger"
                              style="width:100px;border-radius: 20px;"
                              @click="gotoPage('checkin')"
                            >重新填写</el-button>
                          </div>
                        </el-col>
                      </el-row>-->
                      <el-row>
                        <el-col :span="24">
                          <div class="signWrap">
                            <span>
                              <img src="../../assets/seat-able.png">可选
                            </span>
                            <span>
                              <img src="../../assets/seat-unable.png">不可选
                            </span>
                            <span>
                              <img src="../../assets/seat-locked.png">锁定
                            </span>
                            <span>
                              <img src="../../assets/seat-selected.png">选中
                            </span>
                          </div>
                        </el-col>
                      </el-row>
                      <el-row>
                        <el-col :span="4">
                          <div style="height:1px;overflow: hidden;"></div>
                        </el-col>
                        <el-col :span="16">
                          <div class="xWrap">
                            <div class="left">
                              <span>A</span>
                              <span>B</span>
                              <span>C</span>
                            </div>
                            <div class="center">过道</div>
                            <div class="right">
                              <span>D</span>
                              <span>E</span>
                              <span>F</span>
                            </div>
                          </div>
                        </el-col>
                        <el-col :span="4">
                          <div style="height:1px;overflow: hidden;"></div>
                        </el-col>
                      </el-row>
                    </div>
                  </div>
                </div>
              </div>
              <div style="height: 1px;clear:both;overflow: hidden;"></div>
              <el-row>
                <el-col :span="4">
                  <div style="height:1px;overflow: hidden;"></div>
                </el-col>
                <el-col :span="16">
                  <div class="seatsWrap" :style="{ marginBottom: nowSeat ? '0px': '0' }">
                    <div class="yBar">
                      <span v-for="(n,index) in rows" v-bind:key="index">{{n}}</span>
                    </div>
                    <div class="seats">
                      <div class="row" v-for="(row,index) in seatList" v-bind:key="index">
                        <div class="left">
                          <img v-if="row.hasExit" src="../../assets/exit-left.png" class="leftExit">
                          <template v-for="(seat,i) in row.cols.slice(0,3)">
                            <span
                              v-if="seat.state == 1"
                              v-bind:key="i"
                              class="able"
                              @click="chooseSeat(seat)"
                            ></span>
                            <span v-if="seat.state == 2" v-bind:key="i" class="unable"></span>
                            <span
                              v-if="seat.state == 3"
                              v-bind:key="i"
                              class="selected"
                              @click="cancelSeat(seat)"
                            >
                              <p>{{seat.row}}排</p>
                              <p>{{seat.col}}座</p>
                            </span>
                            <span v-if="seat.state == 4" v-bind:key="i"></span>
                            <span v-if="seat.state == 5" class="lock" v-bind:key="i"></span>
                          </template>
                        </div>
                        <div class="center"></div>
                        <div class="right">
                          <img
                            v-if="row.hasExit"
                            src="../../assets/exit-left.png"
                            class="rightExit"
                          >
                          <template v-for="(seat,i) in row.cols.slice(3,6)">
                            <span
                              v-if="seat.state == 1"
                              class="able"
                              @click="chooseSeat(seat)"
                              v-bind:key="i"
                            ></span>
                            <span v-if="seat.state == 2" class="unable" v-bind:key="i"></span>
                            <span
                              v-if="seat.state == 3"
                              class="selected"
                              @click="cancelSeat(seat)"
                              v-bind:key="i"
                            >
                              <p>{{seat.row}}排</p>
                              <p>{{seat.col}}座</p>
                            </span>
                            <span v-if="seat.state == 4" v-bind:key="i"></span>
                            <span v-if="seat.state == 5" class="lock" v-bind:key="i"></span>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-col>
                <el-col :span="4">
                  <div style="height:1px;overflow: hidden;"></div>
                </el-col>
              </el-row>
              <!-- <loading :show="loadingShow" text="数据获取中"></loading>
              <alert
                  :show="error.message != ''"
                  :buttonText="error.buttonText"
                  @on-hide="error.callback"
                  :html="error.message"
              ></alert>-->
            </div>
            <!-- <alert
                :show="error.message != ''"
                :buttonText="error.buttonText"
                @on-hide="error.callback"
                :html="error.message">
            </alert>-->
            <!-- <loading :show="loadingShow" text="数据获取中"></loading> -->
            <div style="margin-top:110px;height:200px">
              <div class="warm-prompt">
                <h3>温馨提示</h3>
                <div>
                  <p>1 预计起飞时间仅作为参考，如发生临时变动，请以现场通知为准；</p>
                  <p>2 航班状态不作为非自愿退改签依据，具体情况请联系原购票地咨询；</p>
                  <p>3 登机口可能会有更改，请在到达机场后查看航班动态显示屏或询问红航柜台人员确定登机口。</p>
                </div>
              </div>
            </div>
            <el-dialog
              title="提示"
              :visible.sync="dialogVisible"
              width="30%"
              :before-close="handleClose"
            >
              <span>值机成功！您的行程信息如下：</span>
              <div>
                <div class="service-query">
                  <ul class="service-forms">
                    <li class="form-group">
                      <label class="form-label" for="certNo">凭证号码：</label>
                      <span>{{userInput.cert}}</span>
                    </li>
                    <li class="form-group">
                      <label class="form-label" for="passengerName">姓名：</label>
                      <span>{{userInput.name}}</span>
                    </li>
                    <li class="form-group">
                      <label class="form-label" for="mobile">行程：</label>
                      <span>{{journey.tours.depAirportCode}}-{{journey.tours.arrAirportCode}}</span>
                    </li>
                    <li class="form-group">
                      <label class="form-label" for="mobile">航班日期：</label>
                      <span>{{journey.tours.flightDate}}</span>
                    </li>
                    <li class="form-group" v-if="nowSeat">
                      <label class="form-label" for="mobile">座位号：</label>
                      <el-tag type="danger">{{nowSeat.row}}排{{nowSeat.col}}座</el-tag>
                    </li>
                  </ul>
                </div>
              </div>
              <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="gotoPage('checkin')">知道了</el-button>
              </span>
            </el-dialog>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import serviceleft from "../../components/ServiceLeft";
import JourneyItem from "../../components/checkin/journeyItem/index.vue";
import StickySidebar from "sticky-sidebar";
import {
  fetchCheckinFlightList,
  cancelCheckin,
  submitCheckin
} from "@/api/newsapi";
export default {
  components: { serviceleft },
  data() {
    return {
      leftPannelSelected: "li_checkin",
      input: "",
      loginType: "AIRPORT",
      seatList: [],
      userInput: {},
      nowSeat: null,
      journey: {},
      rows: [],
      dialogVisible: false,
      loadingShow: false, // loading开关
      error: {
        message: "",
        callback: function() {
          this.error.message = "";
        }.bind(this),
        buttonText: "知道了"
      }
    };
  },
  mounted() {
    this.userInput = JSON.parse(
      localStorage.getItem("airtravel-checkin-userInput")
    );
    this.journey = JSON.parse(
      localStorage.getItem("airtravel-checkin-journey")
    );
    console.log("jou:", this.journey);
    let seatMap = localStorage.getItem("airtravel-checkin-seats");
    // let seatMap = "F AC DF F\n " +
    //     "1**==**1 \n " +
    //     "2**==**2 \n" +
    //     "%Y ABC DEF Y\n " +
    //     "3...==...3 \n " +
    //     "4...==...4 \n " +
    //     "5...==...5 \n " +
    //     "6X**==X**6 \n " +
    //     "7***==***7 \n " +
    //     "8***==***8 \n " +
    //     "9***==***9 \n " +
    //     "10***==***01\n" +
    //     "11***==***11\n" +
    //     "12...EE...21\n" +
    //     "13 ..EE.. 31\n" +
    //     "14...EE...41\n" +
    //     "15***==***51\n" +
    //     "16***==***61\n" +
    //     " == \n" +
    //     "17***==***71\n" +
    //     "18***==***81\n" +
    //     "19***==***91\n" +
    //     "20***==***02\n" +
    //     "21***==***12\n" +
    //     "22***==***22\n" +
    //     "23***==***32\n" +
    //     "24***==***42\n" +
    //     "25***==***52\n" +
    //     "26***==***62\n" +
    //     "27***==***72\n" +
    //     "28...==...82\n" +
    //     "29...==...92\n%"
    // 以_分割成数组，去除前后空格，去除%，过滤掉空数据和过道及舱位头信息
    // 数据存在两种情况，用\n分割和用_分割
    let tempList = seatMap.split("_");
    if (tempList.length <= 1) tempList = seatMap.split("\\n");
    let rowList = tempList
      .map(v => v.replace(/(^\s+)|(\s+$)/g, "").replace(/%/g, ""))
      .filter(
        v => (v != undefined || "" || null || "==") && !isNaN(parseInt(v))
      );
    for (let i = 0; i < rowList.length; i++) {
      // 去除数字和E=
      let seatStr = rowList[i].replace(/\d+/g, "").replace(/[E,=]/g, "");
      let rowNum = parseInt(rowList[i]);
      this.rows.push(rowNum);
      let row = {
        rowNum: rowNum,
        cols: [],
        hasExit: rowList[i].includes("E")
      };
      let cols = [];
      for (let j = 0; j < seatStr.length; j++) {
        // 如果是空则不显示，为.则显示不可选，如果当前行程的舱位是经济舱，则头等舱不可选，如果当前行程舱位是头等舱，则经济舱不可选
        let state = 0;
        if (seatStr[j] == " ") state = 4;
        else if (seatStr[j] == ".") state = 2;
        else if (seatStr[j] == "X") state = 5;
        else state = 1;
        // 舱位判断直接由接口返回，不需要我来判断了
        // else {
        //     if (seatStr.length == 6 && this.journey.tours.cabinClass == "经济舱") state = 1
        //     else if (seatStr.length == 4 && this.journey.tours.cabinClass == "头等舱") state = 1
        //     else state = 2
        // }
        let yColTitle = "ABCDEF";
        let fColTitle = "ACDF";
        let seat = {
          row: rowNum,
          col: seatStr.length == 6 ? yColTitle[j] : fColTitle[j],
          state: state
        };
        cols.push(seat);
      }
      //将头等舱座位补到6座每排
      if (seatStr.length == 4) {
        let space1 = {
          row: rowNum,
          col: "B",
          state: 4
        };
        let space2 = {
          row: rowNum,
          col: "E",
          state: 4
        };
        cols.splice(1, 0, space1);
        cols.splice(4, 0, space2);
      }
      row.cols = cols;
      this.seatList.push(row);
    }

    this.$nextTick(function() {
      console.log("渲染完成");
      new StickySidebar(".signBar", {
        topSpacing: 0,
        bottomSpacing: 0,
        containerSelector: ".flightList",
        innerWrapperSelector: ".signBar__inner"
      });

      // this.$alert('<strong>姓名： <i>HTML</i> 片段</strong><br><strong>行程：</strong><br><strong>航班号：</strong><br><strong>航班日期：</strong><br><strong>座位号：</strong>', '结果反馈', {
      //   dangerouslyUseHTMLString: true,
      //   callback: action => {

      //   }
      // });
    });
  },
  methods: {
    selectPannel(pannelId) {
      // console.log(pannelId);
      document.getElementById("li_booking").setAttribute("class", "");
      document.getElementById("li_aoc").setAttribute("class", "");
      document.getElementById("li_checkin").setAttribute("class", "");
      document.getElementById("li_order").setAttribute("class", "");
      document.getElementById("li_xl").setAttribute("class", "");
      document.getElementById(pannelId).setAttribute("class", "active");
    },
    checkInAction() {
      this.$alert("系统维护，请稍后在试", "温馨提示", {
        confirmButtonText: "已了解",
        callback: action => {
          // this.$message({
          //   type: 'info',
          //   message: `action: ${ action }`
          // });
        }
      });
    },
    chooseSeat(seat) {
      // 取消其他已选座位
      for (let i = 0; i < this.seatList.length; i++) {
        for (let j = 0; j < this.seatList[i].cols.length; j++) {
          if (this.seatList[i].cols[j].state == 3) {
            this.seatList[i].cols[j].state = 1;
          }
        }
      }
      let colIndex = "ABCDEF".indexOf(seat.col);
      let rowIndex = this.rows.indexOf(seat.row);
      this.seatList[rowIndex].cols[colIndex].state = 3;
      this.nowSeat = {
        row: seat.row,
        col: seat.col
      };
    },
    cancelSeat(seat) {
      let colIndex = "ABCDEF".indexOf(seat.col);
      let rowIndex = this.rows.indexOf(seat.row);
      this.seatList[rowIndex].cols[colIndex].state = 1;
      this.nowSeat = null;
    },
    gotoPage(route) {
      this.$router.push({ name: route });
    },
    toConfirm() {
      let _this = this;
      let userInput = JSON.parse(
        localStorage.getItem("airtravel-checkin-userInput")
      );
      let params = {
        certNo: userInput.cert,
        certType: userInput.certType,
        passengerName: userInput.name,
        mobile: userInput.phone,
        flightNo: this.journey.tours.flightNo,
        flightDate: this.journey.tours.flightDate,
        takeOffTime: this.journey.tours.takeOffTime,
        depAirportCode: this.journey.tours.depAirportCode,
        arrAirportCode: this.journey.tours.arrAirportCode,
        cabinClass: this.journey.tours.cabinClass,
        seatNo: this.nowSeat.row + this.nowSeat.col,
        ticketNo: this.journey.ticketNo,
        tourIndex: this.journey.tours.tourIndex,
        boardingGateNumber: this.journey.tours.boardingGateNumber
      };
      _this.loadingShow = true;
      submitCheckin(params)
        .then(res => {
          _this.loadingShow = false;
          if (res.success) {
            localStorage.setItem(
              "airtravel-checkin-success",
              JSON.stringify(res.data)
            );
            this.dialogVisible = true;
          } else {
            this.$message({
              showClose: true,
              message: "值机失败，请核实信息是否正确！",
              type: "warning"
            });
          }
        })
        .catch(err => {
          this.$message({
            showClose: true,
            message: "值机失败，系统异常",
            type: "warning"
          });
        });
    }
  }
};
</script>

<style scoped>
.checkin {
  margin-top: 20px;
  margin-bottom: 20px;
}

.left_pannel {
  height: 500px;
  background-color: #f7f7f7;
  overflow: hidden;
}

.right_pannel {
  min-height: 700px;
  position: relative;
  /* background-color: #f7f7f7;
      border: 1px solid #ededed; */
}

.panel-tabs .active {
  /* background-color: #d6003a; */
  /* color:#FFFFFF */
}

.panel-tabs .active a {
  /* color:#FFFFFF */
}

.col_menu b {
  font-size: 24px;
  margin: 20px 20px 0;
  color: #333;
  display: block;
  font-weight: normal;
  border-bottom: 1px solid #f1f1f1;
}

.col_menu ul {
  margin-bottom: 20px;
}

ul,
ol,
dl,
dd,
dt {
  margin: 0;
  padding: 0;
  display: block;
}

.col_menu li {
  margin: 10px 0;
  position: relative;
}

.col_menu li.current {
  background: none;
}

.col_menu li.current a:link,
.col_menu li.current a:visited,
.col_menu li.current a:active {
  border-left: 2px #ff6600 solid;
  color: #666;
  font-weight: bold;
}

.col_menu li a:link,
.col_menu li a:visited,
.col_menu li a:active {
  display: block;
  line-height: 30px;
  padding: 0 55px 0 20px;
}

a:link,
a:visited,
a:active {
  color: #666;
  text-decoration: none;
}

a {
  color: #337ab7;
  text-decoration: none;
}

.warm-prompt {
  padding: 40px 50px 80px;
}

.warm-prompt p {
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  color: #9d9d9d;
  text-align: left;
}

.service-query {
  position: relative;
  z-index: 10;
  margin: 0 auto;
  /* padding: 8px 44px 0;
    width: 578px; */
  /* background-color: #fff;
    border: 1px solid #f1f1f1;
    border-radius: 4px; */
  /* -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.05);
      box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.05); */
}

.service-query .service-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 55px;
  color: #4d4e4e;
  text-align: center;
  border-bottom: 1px solid #eae7e7;
}

.service-query .service-prompt {
  padding-top: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.8;
  color: #9d9d9d;
  text-align: center;
}

.service-query .service-forms > li {
  margin-top: 10px;
}

.form-group .form-label {
  width: 100px;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 35px;
  color: #4d4e4e;
  text-align: right;
}

.checkin-links.query {
  margin: 24px 35px 24px 75px !important;
}

.agree-protocol {
  margin: 24px 0;
  padding-left: 24px;
  font-size: 0;
}

.link.link-sm {
  height: 18px;
  font-size: 12px;
  line-height: 18px;
}

.link {
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #2c6e8b;
  text-align: left;
}

.search-block {
  width: 1200px;
  margin: auto;
  position: relative;
}

.popcontext .tab {
  z-index: 1000;
  width: 240px;
}

.popcontext .tab li {
  height: 60px;
  line-height: 60px;
  /* border-top: 1px solid #4e617f; */
  background-color: #f5f5f5;
  color: #000000;
  font-size: 14px;
  text-align: center;
  transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  cursor: pointer;
  margin-left: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  filter: alpha(Opacity=60);
  -moz-opacity: 0.6;
  opacity: 0.6;
}

/* border-bottom: 1px solid #d6003a; */
.popcontext .tab li:nth-child(2) {
  border-top: 1px solid #d6003a;
}

.popcontext .tab li:last-child {
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-bottom-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.popcontext .tab li:nth-child(2).active {
  border-top: 1px solid #f9f9f9;
}

.popcontext .tab li.active {
  /* border-top: 1px solid #fff; */
  background-color: #d6003a;
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
  margin-left: 0;
  padding-left: 10px;
  border-right: 1px solid #fff;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
  filter: alpha(Opacity=100);
  -moz-opacity: 1;
  opacity: 1;
}

.popcontext hgroup {
  display: none;
  position: absolute;
  width: 460px;
  top: -410px;
  left: 0px;
  z-index: 1000;
  margin-left: 140px;
  background-color: #fff;
  -webkit-border-radius: 0 5px 5px 0;
  -moz-border-radius: 0 5px 5px 0;
  border-radius: 0 5px 5px 0;
}

.popcontext hgroup article {
  position: relative;
  height: 364px;
  padding-left: 30px;
}

.panel-header {
  /* border-bottom: 2px solid #e9e8e8; */

  width: 400px;
}

.panel-header .panel-tabs {
  display: block;
  width: 100%;
  height: 42px;
  margin: 0;
  padding: 0;
}

.panel-tabs li {
  width: 50%;
  position: relative;
  float: left;
  /* background-color: #EEEEEEFF; */
}

.panel-tabs li:first-child {
  border-radius: 10px 0px 0 0;
}

.panel-tabs li:last-child {
  /* border-radius: 0px 10px 0 0; */
}

.panel-tabs li a {
  position: relative;
  display: block;
  margin: auto;
  width: 80px;
  height: 42px;
  font-size: 14px;
  font-weight: 400;
  line-height: 42px;
  text-align: center;
  cursor: pointer;
}

.panel-tabs .clearfix:after {
  display: block;
  height: 0;
  content: "";
  clear: both;
  overflow: hidden;
}

.panel-tabs .active {
  background-color: #d6003a;
  color: #ffffff;
}

.panel-tabs .active a {
  color: #ffffff;
}

/* .filter {
  width: 100%;
  float: left;
} */

.filter .el-input {
  /* margin-top: 10px; */
}

.el-date-editor .el-input {
  width: 100%;
}

.filter-title {
  margin-top: 10px;
  font-size: 14px;
  color: #7f7e7c;
  line-height: 40px;
  /* font-weight: 700; */
}

.filter-input {
  position: relative;
  padding-top: 10px;
}

.filter-input .el-input input {
  height: 45px !important;
  line-height: 45px;
}

.filter-panel {
  background-color: #ffffff;
  position: absolute;
  top: 45px;
  left: 0;
  width: 424px;
  padding: 8px 16px 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
}

.filter-panel-title {
  color: #9d9d9d;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.el-select-dropdown__item {
  padding-left: 15px;
}

.separet {
  height: 14px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  margin: 0 9px;
  margin-top: 50px;
}

.search-btn {
  width: 80px;
  height: 35px;
  margin-top: 20px;
  margin-left: 32px;
  background-color: #ff0505;
  color: #ffffff;
  border: 0;
  font-size: 14px;
  font-weight: 700;
}

.el-tabs__active-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  background-color: #f7214b;
  z-index: 1;
  -webkit-transition: -webkit-transform 0.3s
    cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  list-style: none;
}

.panel-tabs li.active:after {
  position: absolute;
  right: 0;
  bottom: -2px;
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  content: "";
  background-color: #ce212e;
}

.el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 45px;
  line-height: 45px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}

.aoctable .el-table__header-wrapper table thead tr {
  background-color: #cccccc;
}
</style>
<style lang="less" scoped>
.passengerInfo {
  height: 66px;

  .name {
    font-size: 16px;
    color: #333;
    margin-bottom: 2px;
  }

  .cert {
    font-size: 12px;
    color: #666;
  }
}

.flightList {
  margin-top: 10px;
}

.signBar {
  background-color: #fff;
  height: 200px;
  width: 100%;
  /* padding: 0 15px; */
  z-index: 2;

  .signBar__inner {
    background-color: #ffffff;
  }

  .signWrap {
    width: 400px;
    margin: auto;

    span {
      font-size: 12px;
      color: #999;
      line-height: 36px;
      margin-right: 20px;

      img {
        position: relative;
        width: 16px;
        margin-right: 8px;
        top: 2px;
      }
    }
  }

  &:after {
    content: "";
    display: block;
    position: relative;
    left: -50%;
    bottom: 30px;
    width: 200%;
    height: 0px;
    background: #dcdcdc;
    -webkit-transform: scale(0.5);
  }

  .xWrap {
    height: 30px;
    display: flex;
    line-height: 30px;
    font-size: 14px;
    color: #999;
    text-align: center;
    margin-left: 20px;

    .center {
      flex: 1;
    }

    @media screen and (min-width: 370px) {
      .left,
      .right {
        width: 121px;
        display: flex;

        span {
          flex: 1;
        }
      }

      .left {
        margin-left: 20px;
      }

      .right {
        margin-right: 40px;
      }
    }

    @media screen and (max-width: 370px) {
      .left {
        width: 109px;
        padding-left: 13px;

        span {
          display: block;
          float: left;
          width: 31px;
          margin-right: 8px;
        }

        span:nth-last-child(1) {
          width: 10px;
          margin-right: 0;
          margin-left: 8px;
        }
      }

      .right {
        width: 109px;

        span {
          display: block;
          float: left;
          width: 31px;
          margin-left: 8px;
        }

        span:nth-child(1) {
          width: 10px;
          margin-left: 0;
          margin-right: 8px;
        }
      }
    }
  }
}

.seatsWrap {
  /* position: relative; */
  top: 120px;
  display: flex;

  .yBar {
    width: 20px;
    background-color: #e0e0e3;
    border-radius: 6px;

    span {
      height: 41px;
      line-height: 41px;
      width: 20px;
      display: block;
      text-align: center;
      font-size: 14px;
      color: #999;
    }
  }

  .seats {
    flex: 1;

    .able {
      background-image: url("../../assets/seat-able.png");
    }

    .unable {
      background-image: url("../../assets/seat-unable.png");
    }

    .selected {
      background-image: url("../../assets/seat-selected.png");
    }

    .lock {
      background-image: url("../../assets/seat-locked.png");
    }

    @media screen and (min-width: 370px) {
      .row {
        height: 41px;
        display: flex;

        .left,
        .right {
          position: relative;
          width: 121px;

          .rightExit {
            position: absolute;
            width: 18px;
            top: -9px;
            right: 0;
            margin-right: -30px;
            transform: scaleX(-1);
          }

          .leftExit {
            position: absolute;
            width: 18px;
            top: -9px;
            left: 0;
            margin-left: -30px;
          }
        }

        .center {
          flex: 1;
        }

        .left {
          margin-left: 42px;
        }

        .right {
          margin-right: 42px;
        }
      }

      span {
        margin-top: 5px;
        font-size: 10px;
        color: #fff;
        width: 35px;
        height: 31px;
        display: block;
        background-size: 35px 31px;
        text-align: center;
        line-height: 12px;
        padding-top: 1px;
        float: left;

        &:nth-last-child(n + 2) {
          margin-right: 8px;
        }
      }
    }

    @media screen and (max-width: 370px) {
      .row {
        height: 41px;
        display: flex;

        .left,
        .right {
          position: relative;
          width: 109px;

          .rightExit {
            position: absolute;
            width: 18px;
            top: -9px;
            right: 0;
            margin-right: -22px;
            transform: scaleX(-1);
          }

          .leftExit {
            position: absolute;
            width: 18px;
            top: -9px;
            left: 0;
            margin-left: -22px;
          }
        }

        .center {
          flex: 1;
        }

        .left {
          margin-left: 27px;
        }

        .right {
          margin-right: 27px;
        }
      }

      span {
        margin-top: 5px;
        font-size: 10px;
        color: #fff;
        width: 31px;
        height: 27px;
        display: block;
        background-size: 31px 27px;
        text-align: center;
        line-height: 12px;
        padding-top: 1px;
        float: left;

        &:nth-last-child(n + 2) {
          margin-right: 8px;
        }
      }
    }
  }
}

.confirmWrap {
  height: 88px;
  width: 100%;
  /* position: fixed; */
  z-index: 3;
  background-color: #fff;
  bottom: 0;
  left: 0;

  .seatInfo {
    line-height: 44px;
    padding-left: 15px;
    height: 44px;
    width: 100%;
    font-size: 14px;
    color: #333;
  }

  .confirmBtn {
    background-color: #ea323a;
    height: 44px;
    width: 100%;
    line-height: 44px;
    font-size: 16px;
    color: #fff;
    text-align: center;
  }
}

.fadeIn-enter,
.fadeIn-leave-active {
  transform: translateY(100%);
}

.fadeIn-enter-active,
.fadeIn-leave-active {
  transition: all 0.3s ease;
}
/deep/.filter-title {
  color: #929a9f;
  font-size: 14px;
  width: 70px;
  display: inline-block;
}
/deep/.filter-value {
  color: #515457;
  font-size: 14px;
  display: inline-block;
}
.tour-content {
  font-size: 14px !important;
  display: flex;
  align-items: center;
    // width: 100%;
  // padding: 20px;
  position: relative;
  &-time {
    display: flex;
    margin-top: 10px;
    div {
      margin-right: 20px;
      // display: flex;
    }
  }
  .btn {
    width: 60px;
    padding: 5px;
    background-color: #d70039 !important;
    color: #ffffff;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    height: 20px;
    position: absolute;
    right: 20px;
    font-size: 14px;
  }
}
</style>